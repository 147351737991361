<template>
 <p class="-mt-4 text-gray-700 text-sm">
  {{ $t("resetCreatePasswordDetail") }}
 </p>
 <div class="max-w-md w-full space-y-8">
  <form
   class="space-y-6"
   @submit.prevent="
    onSubmit(
     'Request sent successfully.',
     'Please check your email for the link to reset your password'
    )
   "
   method="POST"
  >
   <input type="hidden" name="remember" value="true" />
   <div>
    <label for="email" class="block text-sm font-medium leading-6 text-gray-900">
     {{ $t("login.emailAddress") }}
    </label>
    <div>
     <input
      required
      v-model="form.email"
      id="email"
      name="email"
      type="email"
      autocomplete="email"
      class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
     />
    </div>
   </div>

   <div class="flex items-center justify-between">
    <div class="text-sm">
     <a @click="$emit('loginPage')" href="#" class="font-medium encom_ouvert_ticket_text">
      {{ $t("login.backToLogin") }}
     </a>
    </div>
   </div>

   <div>
    <button
     type="submit"
     class="rounded-md body__header--button group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none encom_ring_color"
    >
     <span class="absolute left-0 inset-y-0 flex items-center pl-3">
      <LockClosedIcon
       class="h-5 w-5 encom_primary_text group-hover:encom_secondary_text"
       aria-hidden="true"
      />
     </span>
     {{ $t("ticket.send") }}
    </button>
   </div>
  </form>
 </div>
</template>

<script>
import axios from "axios";
import ComfirmationDialog from "../../components/ConfirmationDialog.vue";
import { LockClosedIcon } from "@heroicons/vue/solid";

const logo = localStorage.getItem("logo");

export default {
 props: ["enteredEmail", "queryParams"],
 components: { LockClosedIcon, ComfirmationDialog },
 data() {
  return {
   form: {
    email: "",
   },
   logo,
  };
 },
 methods: {
  async onSubmit() {
   const data = {
    email: this.form.email,
   };
   var first_login = "0";
   if (this.queryParams.first_login) {
    first_login = "1";
   }

   axios
    .post(
     `${this.$cookie.getCookie("API")}/api/v1/requestPassword?first_login=${first_login}`,
     data
    )
    .then((res) => {
     let msg = this.$t("passwordCreate");
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
    })
    .catch((err) => {
     console.error(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
 },
 mounted() {
  this.form.email = this.enteredEmail;
 },
};
</script>

<style></style>
