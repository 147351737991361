<template>
 <div class="min-h-full flex">
  <div
   class="bg-gray-200 flex-1 flex flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
  >
   <div class="mx-auto w-full max-w-lg lg:w-96">
    <img class="max-h-20 w-auto" :src="logo" alt="Welcome" />

    <h2 class="mt-6 text-2xl font-bold text-gray-900">
     {{
      Object.keys(queryParams)[0] === "first_login"
       ? $t("passwordCreate")
       : Object.keys(queryParams)[0] === "reset_password"
       ? $t("passwordReset")
       : $t("signinToAccount")
     }}
    </h2>

    <div class="mt-5">
     <LoginCard
      v-if="showResetPassword == false"
      @setLoading="$emit('setLoading', $event)"
      @resetPassword="(...args) => redirectToResetPwdCard(...args)"
      :isGuestUser="isGuestUser"
     />
     <ResetPasswordCard
      v-if="showResetPassword == true && !isGuest"
      @loginPage="
       (showResetPassword = false), this.$router.replace({ query: {} }), (queryParams = '')
      "
      :enteredEmail="enteredEmail"
      :queryParams="queryParams"
      @activeBanner="$emit('activeBanner')"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
     />
    </div>
   </div>
  </div>
  <div class="hidden lg:block relative w-0 flex-1">
   <img class="absolute inset-0 h-full w-full object-cover" :src="this.background" alt="" />
  </div>
 </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoginCard from "../components/Login/LoginCard.vue";
import ResetPasswordCard from "../components/Login/ResetPasswordCard.vue";

export default {
 props: ["isGuestUser"],
 components: { LoginCard, ResetPasswordCard },
 name: "Login",
 data() {
  return {
   showResetPassword: false,
   enteredEmail: "",
   queryParams: "",
  };
 },
 methods: {
  getBackground() {
   var divId = document.getElementById("background-img");
   divId.style.background = `linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(${this.background}) no-repeat center center fixed`;
   divId.style.backgroundSize = `cover`;
  },
  redirectToResetPwdCard(...args) {
   this.enteredEmail = args[0];
   this.queryParams = args[1];
   this.showResetPassword = true;

   // Redirect to the same route with query parameters
   this.$router.push({ query: this.queryParams });
  },
 },
 mounted() {
  this.$emit("setLoading", false);
  // console.log(this.$route.query);  
  this.$route.query && this.$route.query.first_login ? this.showResetPassword = true : this.showResetPassword = false;
  // this.getBackground();
 },
 updated() {
  // this.getBackground();
 },
 computed: {
  ...mapGetters(["logo", "isGuest", "background"]),
 },
};
</script>

<style>
.login_page {
 background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
  url("../assets/encom_background_1.jpg") no-repeat center center fixed;
 -webkit-background-size: cover;
 -moz-background-size: cover;
 -o-background-size: cover;
 background-size: cover;
 z-index: 0;
}
.login_card {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 min-height: 100vh;
}
#input-group-3__BV_label_ {
 margin-top: 0.2rem;
}
.d-block {
 font-weight: bold;
}
button {
 justify-items: auto;
 margin-inline-end: 0.5rem;
 margin-block-start: 0.5rem;
 margin-top: 0.6rem;
}

.card {
 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 max-width: 50rem;
 min-width: 30rem;
 /* border-radius: 10px; */
}
.card:hover {
 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.404);
}

.card-img-top img:hover {
 transform: scale(1.01);
}

.min-h-screen {
 min-height: 30vh;
}
.bg-gray-50 {
 background-color: rgba(255, 255, 255, 0.733);
}
.form_card {
 /* background-color: rgba(255, 255, 255, 0.733); */
 /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 3px 10px 0 rgba(0, 0, 0, 0.404); */
 transition: 0.3s;
 padding: 0.1rem 10px 10px 10px;
 /* border-radius: 10px; */
}
.form_card #email-address {
 margin-top: -10px;
 margin-bottom: 10px;
}

.forgot_margin a {
 margin-left: 50px;
}

@media only screen and (max-width: 700px) {
 .card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-width: 22rem;
  /* border-radius: 10px; */
 }
 .form_card {
  max-height: 100vh;
  max-width: 50vh;
 }
 .login_page {
  overflow-x: hidden;
  overflow-y: hidden;
 }
 .max-w-md {
  max-height: 100vh;
  max-width: fit-content;
 }
 .max-w-md img {
  max-height: 150px;
 }
}
</style>
